import * as React from 'react';
import { colors } from '../components/Layout/colors';
import Layout from '../components/Layout/Layout';
import Seo from '../components/seo';
import AboutHome from '../components/AboutHome';
import HomeHero from '../components/HomeHero';
import Offer from '../components/Offer';
import PricingSection from '../components/PricingSection';
import Testimonials from '../components/Testimonials';
import styled from 'styled-components';

const IndexBg = styled.div`
    background: ${colors.bg.lightB};
`;

const IndexPage = () => (
    <IndexBg>
        <Layout>
            <Seo title="Dentist in Santa Rosa Beach" />
            <HomeHero />
            <AboutHome />
            <Offer />
            <PricingSection />
            <Testimonials />
        </Layout>
    </IndexBg>
);

export default IndexPage;
