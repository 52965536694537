import React from "react"
import styled from "styled-components"
import { colors } from "./Layout/colors"
import beforeIcon from "../images/pricing-icon-two.svg"

const Plan = styled.div`
  background: white;
  box-shadow: 0px 25px 55px rgba(126, 126, 126, 0.15);
  border-radius: 8px;
  position: relative;
  padding: 48px 32px 32px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.4s;

  &::before {
    content: "";
    position: absolute;
    top: -32px;
    left: 32px;
    width: 64px;
    height: 64px;
    background: ${colors.bg.lightB};
    border-radius: 8px;
    border:3px solid #2FC6E7;
    background-image: url(${beforeIcon});
    background-repeat: no-repeat;
    background-position: center;
  }

  h5,
  h3,
  li {
    color: ${({ existing }) => existing && `${colors.text.white} !important`};
    &::marker {
      color: ${({ existing }) => existing && `${colors.text.white} !important`};
    }
  }
`

const Price = styled.h3`
  color: ${colors.bg.base80};
  margin: 0;
  line-height: 64px;
  font-feature-settings: "lnum" 1;
`

const List = styled.ul`
  border-top: ${({ existing }) =>
    `1px solid ${existing ? colors.text.white : colors.bg.base80}`};
  padding-inline-start: 1.5rem;
  padding-top: 8px;
`

const Feature = styled.li`
  color: ${colors.text.title};
  padding: 8px 0;

  &::marker {
    font-size: 1.5rem;
    color: ${colors.bg.baseD};
  }
`
const CallBtn = styled.button`
  padding: 16px 24px;
  background:#0E5CA5;
  margin-top: 16px;
  align-self: flex-start;
`

export default function PricingPlan({ title, price, features, existing }) {
  return (
    <Plan existing={existing}>
      <div>
        <h5 style={{ color: 'colors.bg.baseD', margin: 0 }}>{title}</h5>
        <Price>${price}</Price>
        <List existing={existing}>
          {features &&
            features.map((feature, i) => <Feature key={i}>{feature}</Feature>)}
        </List>
      </div>
      <a href="tel:850-660-3616"><CallBtn>Call Us</CallBtn></a>
    </Plan>
  )
}
