import React from "react"
import { colors } from "./Layout/colors"
import styled from "styled-components"
import {StaticImage} from 'gatsby-plugin-image'
import rightArrow from "../images/right-arrow.png"
import { Swiper, SwiperSlide } from "swiper/react"
import 'swiper/css/bundle'

const TestimonialSection = styled.section`
  text-align: center;
  padding: 48px 0 0;

`

const SliderContainer = styled.div`
  position: relative;

  .swiper {
    padding: 0 8px;

    .swiper-slide-visible {
      > div {
        box-shadow: 0px 25px 55px rgba(126, 126, 126, 0.15);

      }
    }

  }

  .stars{
    display:flex;
    justify-content:center;
    margin-bottom:2em;
  }
  h6{
    margin-bottom:1em;
  }
  margin: 0 auto;
  display:flex;

  @media (min-width: 640px) {
    max-width:1199px;

    .swiper {
      padding: 0em 40px;

      .swiper-slide-visible {
        > div {

  box-shadow: 0px 25px 55px rgba(126, 126, 126, 0.15);

    } 
    } 
    } 
    
  }
`

const SingleSlide = styled.div`
  background: ${colors.text.white};
  margin: 48px auto 96px;
  border-radius: 16px;
  padding: 64px 16px 1px;
  width: calc(100vw - 60px);
  transform: box-shadow 0.3s ease-in-out;

  position: relative;
  @media (min-width: 640px) {
    width: 98%;
    background: ${colors.text.white};
  margin: 48px auto 96px;
  
  border-radius: 16px;
  padding: 64px 16px 1px;
  position: relative;
  }

  @media (min-width: 1036px) {
 
  }
`

const ImageWrapper = styled.div`
  display: grid;
  place-items: center;
  width: 88px;
  height: 88px;
  border-radius: 50%;
  position: absolute;
  top: -44px;
  left: 50%;
  transform: translateX(-50%);
  background: ${colors.bg.base40};
  overflow: hidden;
`

const Testimonial = styled.p`
  color: ${colors.text.body};
  line-height: 24px;
  margin-bottom: 32px;
  text-align: left;

  @media (min-width: 1036px) {
    margin-bottom: 64px;
  }
`

const NavigationBtn = styled.button`
  width: 56px;
  height: 56px;
  position: absolute;
  bottom: 116px;
  left: 8px;
  z-index: 50;
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    transform: scaleX(-1);

    background-image: url(${rightArrow});
    background-position: center;
    background-repeat: no-repeat;
  }

  & + button {
    right: 8px;
    left: unset;

    &::before {
      transform: scaleX(1);
    }
  }

  @media (min-width: 640px) {
    bottom: 49%;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    left: 1%;

    & + button {
      right: 1%;
      left: unset;
    }
  }
`
// provide testimonials in this structure to testimonials variable. If no image leave it, automatically uses user name first letter
const testimonials = [
  {
    text:
      "Santa Rosa Smiles is compassionate about their patients! The atmosphere is great and they truly care about each other and their patients. I would definitely recommend this clinic. Susan is my hygienist and she is just AWESOME!!",
    user: "Katie R.",
  },
  {
    text:
      "The staff are very helpful and friendly. They will make sure you are comfortable, and at ease with whatever procedure you have going on. I have multiple procedures going on and they have been very good at planning them out and getting everything done.",
    user: "Ron K.",
  },
  {
    text:
      "Marina was very patient with me and answered all my questions and put me at ease. Everyone in the office is very friendly and helpful. I have more to come visits to come and no more fear. Highly recommend Santa Rosa Smiles",
    user: "Lisa H.",
  },
  {
    text:
      "My experience with Santa Rosa Smiles was absolutely great. The entire team is so professional, knowledgeable, experienced and even humorous! Yes, the energy is amazing and each patient is treated like a family member.",
    user: "Jeff R.",
  },
  {
    text:
      "I had a wonderful experience at Santa Rosa Smiles. From the excellent customer service to finding a GREAT in-house insurance plan, I felt like I was really taken care of. Dr. Frigo and her team are fantastic and I’m so happy we have found our local, family dentist!!",
    user: "Alecia M.",
  },
]

export default function Testimonials() {
  const swiperRef = React.useRef(null)
  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext()
    }
  }
  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev()
    }
  }
  return (
    <TestimonialSection>
      <h4 style={{ color: colors.bg.base80 }}>What our patient says</h4>
      <SliderContainer>
        <Swiper ref={swiperRef} watchSlidesProgress breakpoints={{
    768: {
      slidesPerView: 2,
    },
    1280: {
       slidesPerView: 3,
    },
  }}  spaceBetween={48}>
          {testimonials.map((testimonial, i) => (
            <SwiperSlide key={i}>
              <SingleSlide>
                <ImageWrapper>
                  {testimonial.img ? (
                    <img src={testimonial.img} alt={testimonial.user} />
                  ) : (
                    <h4 style={{ margin: 0, color: colors.text.white }}>
                      {testimonial.user[0]}
                    </h4>
                  )}
                </ImageWrapper>
                <Testimonial>{testimonial.text}</Testimonial>
                <h6>{testimonial.user}</h6>
                <div className='stars'>
                <StaticImage className='star' src='../images/star.svg' alt='star icon' />
                <StaticImage className='star' src='../images/star.svg' alt='star icon' />
                <StaticImage className='star' src='../images/star.svg' alt='star icon' />
                <StaticImage className='star' src='../images/star.svg' alt='star icon' />
                  <StaticImage className='star' src='../images/star.svg' alt='star icon' />
                </div>
              </SingleSlide>
            </SwiperSlide>
          ))}
        </Swiper>
        <NavigationBtn onClick={goPrev} />
        <NavigationBtn onClick={goNext} />
      </SliderContainer>
    </TestimonialSection>
  )
}
