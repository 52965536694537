import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { colors } from "./Layout/colors"
import HeroBg from "../images/home-hero/home-hero-bg.png"
import HeroBgBig from "../images/home-hero/home-hero-bg-big.jpg"
import HeroBgDesktop from "../images/home-hero/home-hero-bg-desktop.jpg"
import phone from "../images/layout/nav/phone.png"

const Hero = styled.section`
  background-image: url(${HeroBg});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  min-height: 100vh;
  padding: 182px 0px 52px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 640px) {
    min-height: 110vh;
    background-image: url(${HeroBgBig});
  }

  @media (min-width: 1024px) {
    background-image: url(${HeroBgDesktop});
    padding-top: 176px;
    padding-bottom: 72px;
  }

  @media (min-width: 1280px) {
    padding: 176px 104px 72px;
  }
`

const DesktopContainer = styled.div`
.address-icon{
  position:absolute;
  bottom:0.7em;
  display:none;
  left:10.2em;
}
  padding-left: 32px;
  padding-right: 32px;
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 768px) {
    .address-icon{
 
  position:absolute;
  bottom:13.7em;
  left:10.2em;
}
  }
  @media (min-width: 1024px) {
    .address-icon{
      display:block;
  position:absolute;
  bottom:13.7em;
  left:10.2em;
}
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

const DesktopInfo = styled.div`
  display: none;
  @media (min-width: 1024px) {
    display: block;
  }
`

const DesktopInfoWrapper = styled.div`
background: rgba(30, 77, 134, 0.62);
backdrop-filter: blur(8px);
/* Note: backdrop-filter has minimal browser support */
height:550h;
display:flex;
margin:0 auto;
width:295px;
flex-direction:column;
align-items:center;
border-radius: 64px;
padding-top:1em;
.social{
  width:130px;
  display:flex;
  justify-content:space-between;
  margin:1em 0em;
  padding:1em 0em;
}
.logo{
  margin-top:1em;
  width:80px;
}
@media (min-width:768px){
  padding-top:2em;
  width:465px;
  margin:0 auto;
}
  @media (min-width: 1024px) {
    .logo{
      margin-bottom:1em;
  margin-top:0em;
width:130px;
    }

    flex-shrink: 0;
    width:565px;
    height:532px;
  }
`

const BookBtn = styled.button`
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  padding: 16px 48px;
  margin-left:4em;
  text-transform: uppercase;
  margin-top: 32px;

`

const Title = styled.h4`
  color: ${colors.text.white};
  font-feature-settings: "pnum" on, "lnum" on;
text-align:center;
width:65%;
margin:.5em 0em 0em;
  @media (min-width: 640px) {
    width:100%;
    font-size: 48px;
    margin:0em;
  }
  @media (min-width: 1024px) {

  }
`

// const SmileWrapper = styled.div`
//   grid-column: 1 / span 3;
//   grid-row: 2 / 6;
//   max-width: 100%;
//   border-radius: 8px;
//   overflow: hidden;
// `

// const CheeseWrapper = styled.div`
//   grid-column: 4 / span 2;
//   grid-row: 1 / span 2;
//   max-width: 100%;
//   border-radius: 8px;
//   overflow: hidden;
// `

const Address = styled.p`
  color: ${colors.text.white};
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.1em;
  font-feature-settings: "pnum" on, "lnum" on;
  padding-left: 136px;
  min-height: 104px;
  background-repeat: no-repeat;
  background-position: top left;
`

// const IceWrapper = styled.div`
//   grid-column: 4 / span 2;
//   grid-row: 3 / span 2;
//   max-width: 100%;
//   border-radius: 8px;
//   overflow: hidden;
// `
const CallBox = styled.div`
  margin-bottom: 48px;
  width: max-content;
  margin: 24px 0 48px 16px;
  
  @media (min-width: 640px) {
    margin-bottom: 250px;
  }

  @media (min-width: 1024px) {
    display: none;
  }
`

const PhoneNumber = styled.p`
  color: ${colors.text.white};
  font-weight: 700;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 16px;
    height: 16px;
    margin-right: 16px;
  }
  @media (min-width:650px) and (max-width:1020px){
    background: rgba(30, 77, 134, 0.62);
backdrop-filter: blur(16px);
padding:1em .5em;
border-radius:10px;
  }
`

export default function HomeHero() {
  return (
    <Hero >
      <DesktopContainer >
        <DesktopInfoWrapper>
        <StaticImage className='logo' src='../images/hero-logo.png' alt='santa rosa smiles logo' loading="eager" placeholder="none"/>
          <Title>
            Smiles for all
            Ages!
          </Title>
          <StaticImage className='address-icon' src="../images/home-hero/address.svg" alt='shop icon' loading="eager" placeholder="none"/>
          <DesktopInfo>
           
            <a href="https://www.google.com/maps/place/Santa+Rosa+Smiles/@30.3728021,-86.2223634,17z/data=!3m1!4b1!4m5!3m4!1s0x8893e3c1435da779:0xc13c255123cf6272!8m2!3d30.3728021!4d-86.2201747" target="_blank" rel="noreferrer"><Address>
              31 E Mack Bayou Dr
              <br />
              Santa Rosa Beach, FL
              <br /> 32459
            </Address>
            </a>
            <a href="#book-appointment"><BookBtn>Book an appointment</BookBtn></a>
          </DesktopInfo>
          <div className="social">
            <a href="https://www.facebook.com/SantaRosaSmiles/" target="_blank" ><StaticImage className='facebook-icon' src="../images/home-hero/facebook.svg" alt='facebook icon' loading="eager" placeholder="none"/></a>
            <a href="https://www.instagram.com/santarosasmiles/" target="_blank" ><StaticImage className='instagram-icon' src="../images/home-hero/instagram.svg" alt='instagram icon' loading="eager" placeholder="none"/></a>
          </div>
        </DesktopInfoWrapper>
      </DesktopContainer>
      <CallBox>
        
        <PhoneNumber>
          <img src={phone} alt="" /> 850-660-3616
        </PhoneNumber>
        
        <button style={{ padding: "16px 48px" }}>Schedule</button>
      
      </CallBox>
 
    </Hero>
  )
}
