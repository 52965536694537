import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

const About = styled.section`
  max-width: 1650px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 768px) {
    margin-top:-6em;
    display: grid;
    grid-template-columns: minmax(50%, 608px) minmax(50%, 680px);
    max-width: fit-content;
  }

  @media (min-width: 1280px) {
    margin-top:-2em;
    gap: 48px;
  }
`

const Info = styled.div`
  padding: 32px;

  @media (min-width: 768px) {
    margin-top: 32px;
    max-width: 580px;
  }
`

const Tagline = styled.p`
  letter-spacing: 0.1em;
  font-weight: 700;
  text-transform: uppercase;
`
const Heading = styled.h4`
  margin-top: 32px;
  margin-bottom: 32px;
`

const Text = styled.p`
  line-height: 24px;
  margin: 0;
`

export default function AboutHome() {
  return (
    <About>
      <StaticImage
        src="../images/about-home.png"
        alt="about us"
        quality={100}
        style={{ maxWidth: "608px" }}
        imgStyle={{
          borderTopRightRadius: "136px",
          borderBottomLeftRadius: "136px",
          maxWidth: "608px",
        }}
        placeholder="none"
      />
      <Info>
        <Tagline>ABOUT US</Tagline>
        <Heading>Providing a Home like Family experience</Heading>
        <Text>
          Is your family trying to find a new dentist in Santa Rosa Beach, FL?
          Come by and visit our brand-new office at Santa Rosa Smiles! Our team
          welcomes patients of all ages, and we offer a large variety of
          treatment options for you to choose from. We even have an emergency
          dentist in Santa Rosa who can care for dental emergencies if you’re in
          need of urgent dental care. Ready to schedule your appointment with a
          dentist near you? Give our team a call, or fill out our online form to
          book your visit now. Our team is looking forward to meeting you soon!
        </Text>
      </Info>
    </About>
  )
}
